body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  text-decoration: none;
  display: block;
}
ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
.alice-carousel__dots-item {
  cursor: pointer;
}


/* form csss */

.form-header{
  background-Color: #5F2C0F;
}
.form-header p{
  color:white;
 
}
.forms-s label{
  width: 100%;
}

.forms-s label[for=PrivacyCheckbox]{
  margin-top: 10px;

}
#PrivacyCheckbox{
  margin-top:-50px;
}
.forms-s button{
  background-Color: #5F2C0F;
  border-radius: 10px;
  width: 100%;
}

.mobile{
  display: none;
}
@media only screen and (max-width:600px) {
  .form-2-section{
    display:none;
  }
 .mobile{
  display: block;
 }
  
}

.otp-box div{
  background-color: rgb(227, 227, 227);
}

.skip-button{
  background-color:#5F2C0F; 
  color: white;
  padding: 20px;
  border-radius: 10px;

}
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ccc;
}

th, td {
  padding: 0.75rem;
  text-align: left;
}

th {
  background-color: #f8f9fa;
}

tr:hover {
  background-color: #f1f1f1;
}

a {
  color: #2563eb;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.container {
  margin-bottom: 3rem; /* Adds 48px space between table and footer */
}

footer {
  margin-top: 72px;
}
/* CheckoutForm.css */
.checkout-form {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.checkout-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.payment-element {
  margin-bottom: 20px;
}

.checkout-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #0070f3;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.checkout-button:hover {
  background-color: #005bb5;
}

