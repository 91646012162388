@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeSemiBold.ttf");
  font-family: "Manrope";
  font-weight: 600;
}
